<template>
  <o-sheet
    :class="$s.SheetContainer"
  >
    <o-nav
      :class="$s.NavLinks"
      variant="secondary"
    >
      <o-nav-link
        v-for="(link, idx) in navbarData.links"
        :key="idx"
        :to="link.to"
        :class="[$s.NavLink, $s.NavLinkSpecific]"
      >
        {{ link.name }}
        <template
          v-if="link.nested"
          slot="children"
        >
          <o-nav-link
            v-for="(nestedLink, index) in link.links"
            :key="index"
            :to="nestedLink.to"
            :class="$s.NestedNavLink"
            @click.native="linkClick(link)"
          >
            <span>{{ nestedLink.name }}</span>
          </o-nav-link>
        </template>
      </o-nav-link>
    </o-nav>
    <div :class="$s.ButtonContainer">
      <o-button
        :href="navbarData.account.login.to"
        :class="$s.Login"
        full-width
      >
        {{ navbarData.account.login.text }}
      </o-button>
      <o-button
        :href="navbarData.account.signup.to"
        :class="$s.SignUp"
        variant="primary"
        full-width
        @click.prevent="userSignUp"
      >
        {{ navbarData.account.signup.text }}
      </o-button>
    </div>
  </o-sheet>
</template>

<script>
import { OSheet } from 'orbit-ui/components/Sheet';
import { ONav, ONavLink } from 'orbit-ui/components/Nav';
import { OButton } from 'orbit-ui/components/Button';
import { OIcon } from 'orbit-ui/components/Icon';
import { CLICK } from '@weebly/aragorn-analytics/dist/constants/actions';
import { NAVIGATION_BUTTON, SIGNUP_BUTTON } from '@weebly/aragorn-analytics/dist/constants/categories';
import Url from 'url-parse';

import getTrackingLocation from './utils/tracking-helpers';

export default {
  components: {
    OSheet,
    ONav,
    ONavLink,
    OButton,
    OIcon,
  },

  data() {
    return {
      // We set the v-model of accordion to -1
      // to have it default to a closed state
      accordionDefaultValue: -1,
      navbarData: this.$store.state.pageContent.global.headerNavbar,
    };
  },

  methods: {
    linkClick(link) {
      this.trackNavClick(link);
      this.closeSheet();
    },

    closeSheet() {
      this.$emit('close:mobile');
    },

    trackNavClick(link) {
      if (link.to) {
        const url = new Url(link.to);
        const trackName = url.pathname.replace('/', '').replace(/(\/)|(-)/g, '_');
        this.$analytics.track({
          type: 'standard',
          location: getTrackingLocation(this.$router),
          object_category: NAVIGATION_BUTTON,
          action: CLICK,
          object_instance: trackName,
        });
      }
    },

    userSignUp() {
      this.$analytics.track({
        type: 'standard',
        location: getTrackingLocation(this.$router),
        object_category: SIGNUP_BUTTON,
        action: CLICK,
        object_instance: 'top_right',
      }).finally(() => {
        window.location = this.navbarData.account.signup.to;
      });
    },
  },
};
</script>

<style lang="scss" module="$s">
.SheetContainer {
  top: var(--navbar-height);

  header {
    display: none;
  }

  main {
    padding: 0 0 60px 0;
    width: 100%;
    padding-top: 0 !important;
    margin: 0 auto;
    @include content-max-width;
  }
}

.ButtonContainer {
  text-align: center;
  margin: var(--space-x4) 0 0;

  .Login,
  .SignUp {
    display: block;
    margin: var(--space-x2) auto;

    a {
      font-size: 16px;
    }
  }
}

.NavLinks {
  .NavLink.NavLinkSpecific {
    border-bottom: 1px solid #E5E5E5;

    a, button {
      position: relative;
      color: $text-black;
      padding: var(--space-x3) 0;
      font-weight: var(--font-weight-medium);
    }

    .NestedNavLink {
      a {
        color: var(--color-gray-50);
        font-size: 14px;
        line-height: 16px;
        padding: var(--space-x2) 0;
      }
    }
  }
}

.Logo {
  position: relative;
  bottom: 7px;
  left: 1px;
}
</style>
