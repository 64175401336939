<template>
  <div :class="$s.FooterContainer">
    <div :class="$s.LanguagePicker">
      <!--
        when you're ready to have this functional,
        reverting PR 137 might be a good start
      -->
      <span :class="$s.LanguageTitle">{{ currentLanguageName }}</span>
    </div>
    <div :class="$s.SocialLinks">
      <a
        :class="[$s.SocialLink, $s.Facebook]"
        href="//www.facebook.com/weebly/"
        aria-label="Weebly Facebook"
      >
        <FacebookLogo />
      </a>
      <a
        :class="[$s.SocialLink, $s.Twitter]"
        href="//twitter.com/weebly"
        aria-label="Weebly Twitter"
      >
        <TwitterLogo />
      </a>
      <a
        :class="[$s.SocialLink, $s.YouTube]"
        href="//www.youtube.com/user/weebly"
        aria-label="Weebly YouTube"
      >
        <YouTubeLogo />
      </a>
      <!--
        This hidden svg is here to fix a chrome bug.
        For some reason, chrome does not want to display the proper
        gradient for the instagram logo on hover, unless we place
        another of the identical svg outside of the a tag.
      -->
      <InstagramHoverLogo
        :class="$s.Hidden"
      />
      <a
        :class="[$s.SocialLink, $s.Instagram]"
        href="//www.instagram.com/weebly"
        aria-label="Weebly Instagram"
      >
        <div>
          <InstagramLogo
            :class="$s.Default"
          />
          <InstagramHoverLogo
            :class="$s.Hover"
          />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { ONav, ONavLink } from 'orbit-ui/components/Nav';
import { OButton } from 'orbit-ui/components/Button';
import { OIcon } from 'orbit-ui/components/Icon';
import { OPopover } from 'orbit-ui/components/Popover';

import FacebookLogo from '~/assets/img/footer/facebook.svg';
import TwitterLogo from '~/assets/img/footer/twitter.svg';
import YouTubeLogo from '~/assets/img/footer/youtube.svg';
import InstagramLogo from '~/assets/img/footer/instagram.svg';
import InstagramHoverLogo from '~/assets/img/footer/instagram-hover.svg';

import supportedLanguages from '~/middleware/utils/supported-languages';

export default {
  name: 'FooterNav',

  components: {
    OButton,
    ONav,
    ONavLink,
    OIcon,
    OPopover,
    FacebookLogo,
    TwitterLogo,
    YouTubeLogo,
    InstagramLogo,
    InstagramHoverLogo,
  },

  data() {
    return {
      footerSocialData: this.$store.state.pageContent.global.footerSocial,
      languages: supportedLanguages,
    };
  },

  computed: {
    currentLanguageName() {
      const currentLanguageCode = this.$store.state.language;
      const currentLanguage = this.languages.find(language => currentLanguageCode === language.param);
      return currentLanguage ? currentLanguage.name : 'English';
    },
  },
};
</script>

<style lang="scss" module="$s">
.FooterContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NavContainer {
  max-height: 350px;
  overflow: scroll;
}

.SocialLinks {
  text-align: right;
  height: 16px;

  .SocialLink {
    display: inline-block;
    margin-left: var(--space-x2);
    color: var(--color-gray-50);
    cursor: pointer;
    position: relative;
    vertical-align: top;

    @media(--for-small-up) {
      margin-left: var(--space-x3);
    }

    &.Instagram {
      height: 18px;
      width: 18px;

      .Default,
      .Hover {
        transition: opacity var(--transition-base);
        position: absolute;
        top: 0;
        left: 0;
      }

      .Hover {
        opacity: 0;
      }

      .Default {
        opacity: 1;
      }
    }

    svg {
      path {
        transition: fill var(--transition-base);
      }
    }

    &:hover {
      &.Instagram {
        .Default {
          opacity: 0;
        }

        .Hover {
          opacity: 1;
        }
      }

      &.Facebook {
        svg path {
          fill: #3b5998;
        }
      }

      &.Twitter {
        svg path {
          fill: #1da1f2;
        }
      }

      &.YouTube {
        svg path {
          fill: #ff0000;
        }
      }
    }
  }
}

.LanguageTitle {
  font-weight: var(--font-weight-medium);
}

.LanguageIndicatorArrow {
  position: relative;
  bottom: 2px;
  left: 2px;
}

.Hidden {
  opacity: 0;
  width: 0;
  height: 0;
}
</style>
