<template>
  <span>
    <span
      v-if="clickAction"
      :class="$s.ActionNavLink"
      @click="clickAction"
    >
      <slot/>
    </span>
    <nuxt-link
      v-else-if="shouldUseNuxtLink"
      :to="href"
      :class="$s.NavLink"
    >
      <slot/>
    </nuxt-link>
    <a
      v-else
      :href="href"
      :class="$s.NavLink"
    >
      <slot/>
    </a>
  </span>
</template>

<script>
// Inspired by https://github.intern.weebly.net/weebly/orbit/blob/master/src/utils/SmartLink.vue
// Orbit does not export smart link...yet. hence we are not using the name <smart-link> here
export default {
  name: 'KingsLink',

  props: {
    href: {
      type: String,
      required: true,
    },
    clickAction: {
      type: Function,
      default: null,
    },
  },

  computed: {
    isExternalLink() {
      const regex = /^(https?:)?\/\//i;
      return (typeof this.href === 'string' && regex.test(this.href));
    },

    hasRoute() {
      if (this.$router) {
        const resolved = this.$router.resolve(this.href);
        return (resolved.resolved.matched.length > 0);
      }
      return false;
    },

    shouldUseNuxtLink() {
      return this.hasRoute && !this.isExternalLink;
    },
  },
};
</script>

<style module="$s" lang="scss">
.NavLink {
  text-decoration: inherit;
  color: inherit;
}

.ActionNavLink {
  text-decoration: inherit;
  color: inherit;

  &:hover {
    cursor: pointer;
  }
}
</style>

