<template>
  <o-nav
    :class="$s.NavContainer"
    horizontal
  >
    <li
      :class="[$s.MobileNavIcon, {
        [$s.Active]: !!mobileNavLayer,
      }]"
      @click="toggleMobileTakeover"
    >
      <span :class="$s.IconBar" />
    </li>
    <o-nav-link
      :class="$s.WeeblyLogo"
      to="//www.weebly.com"
      aria-label="Weebly"
    >
      <WeeblySquareLogo
        :class="$s.DesktopLogo"
      />
      <WeeblyLogotype
        :class="$s.MobileLogo"
      />
    </o-nav-link>
    <o-nav-link
      v-for="(link, idx) in navbarData.links"
      :key="idx"
      :to="link.to"
      :class="[
        $s.NavLink,
        $s.NavLinkSpecific,
        $s.NavLinkMoreSpecific,
        { [$s.firstNavLink]: idx === 0 }
      ]"
      @click="trackNavClick(link)"
    >
      <span v-if="!link.nested">{{ link.name }}</span>
      <o-popover
        v-if="link.nested"
        placement="bottom"
        offset="0, 24"
      >
        <template
          slot="action"
          slot-scope="popover"
        >
          <span
            :class="$s.NestedLinkTitle"
            @click="popover.toggle(); trackMorePopover()"
          >
            <span>{{ link.name }}</span>
            <o-icon
              :class="$s.NavLinkIndicatorArrow"
              name="indicator-arrow-down"
            />
          </span>
        </template>
        <o-nav
          variant="secondary"
        >
          <o-nav-link
            v-for="(nestedLink, index) in link.links"
            :key="index"
            :to="nestedLink.to"
            :class="$s.NestedNavLink"
            @click="trackNavClick(link)"
          >
            <span>{{ nestedLink.name }}</span>
          </o-nav-link>
        </o-nav>
      </o-popover>
    </o-nav-link>
    <li :class="$s.AccountContainer">
      <a
        :href="navbarData.account.domain.to"
        :class="$s.DomainButton"
        variant="text"
        @click.prevent="searchDomains"
      >
        <o-icon name="search" />
        {{ navbarData.account.domain.text }}
      </a>
      <a
        :href="navbarData.account.login.to"
        :class="$s.LoginButton"
      >
        {{ navbarData.account.login.text }}
      </a>
      <o-button
        :href="navbarData.account.signup.to"
        :class="[$s.SignUpButton, {
          [$s.Active]: !mobileNavLayer,
        }]"
        variant="primary"
        size="large"
        @click.prevent="userSignUp"
      >
        {{ navbarData.account.signup.text }}
      </o-button>
    </li>
  </o-nav>
</template>

<script>
import { ONav, ONavLink } from 'orbit-ui/components/Nav';
import { OButton } from 'orbit-ui/components/Button';
import { OIcon } from 'orbit-ui/components/Icon';
import { SheetLayer } from 'orbit-ui/components/Sheet';
import { OPopover } from 'orbit-ui/components/Popover';
import Url from 'url-parse';
import debounce from 'lodash.debounce';
import WeeblyLogotype from '~/assets/img/weebly-logotype.svg';
import WeeblySquareLogo from '~/assets/img/weebly-square-logo.svg';
import { CLICK } from '@weebly/aragorn-analytics/dist/constants/actions';
import { NAVIGATION_BUTTON, SIGNUP_BUTTON } from '@weebly/aragorn-analytics/dist/constants/categories';

import MobileMenuTakeover from './MobileMenuTakeover';
import getTrackingLocation from './utils/tracking-helpers';

let debouncedResizeWindowHandler = null;

export default {
  name: 'HeaderNavbar',

  components: {
    OButton,
    ONav,
    ONavLink,
    OIcon,
    OPopover,
    WeeblyLogotype,
    WeeblySquareLogo,
  },

  inject: {
    takeoverLayer: SheetLayer,
  },

  data() {
    return {
      navbarData: this.$store.state.pageContent.global.headerNavbar,
      mobileNavActive: false,
      mobileNavLayer: null,
    };
  },

  mounted() {
    debouncedResizeWindowHandler = debounce(this.closeMobileTakeover.bind(this), 200, { leading: true });
    window.addEventListener('resize', debouncedResizeWindowHandler);
  },

  beforeDestroy() {
    window.removeEventListener('resize', debouncedResizeWindowHandler);
  },

  methods: {
    toggleMobileTakeover() {
      if (this.mobileNavLayer) {
        this.closeMobileTakeover();
      } else {
        this.$analytics.track({
          type: 'standard',
          location: getTrackingLocation(this.$router),
          object_category: NAVIGATION_BUTTON,
          action: CLICK,
          object_instance: 'hamburger',
        });

        this.mobileNavLayer = this.takeoverLayer.open(MobileMenuTakeover, {
          on: {
            'close:mobile': this.closeMobileTakeover,
          },
        });
      }
    },

    userSignUp() {
      this.$analytics.track({
        type: 'standard',
        location: getTrackingLocation(this.$router),
        object_category: SIGNUP_BUTTON,
        action: CLICK,
        object_instance: 'top_right',
      }).finally(() => {
        window.location = this.navbarData.account.signup.to;
      });
    },

    closeMobileTakeover() {
      if (this.mobileNavLayer) {
        this.mobileNavLayer.close();
        this.mobileNavLayer = null;
      }
    },

    searchDomains() {
      this.$analytics.track({
        type: 'standard',
        location: getTrackingLocation(this.$router),
        object_category: NAVIGATION_BUTTON,
        action: CLICK,
        object_instance: 'domain_search',
      }).finally(() => {
        window.location = this.navbarData.account.domain.to;
      });
    },

    trackNavClick(link) {
      if (link.to) {
        const url = new Url(link.to);
        const trackName = url.pathname.replace('/', '').replace(/(\/)|(-)/g, '_');
        this.$analytics.track({
          type: 'standard',
          location: getTrackingLocation(this.$router),
          object_category: NAVIGATION_BUTTON,
          action: CLICK,
          object_instance: trackName,
        });
      }
    },

    trackMorePopover() {
      this.$analytics.track({
        type: 'standard',
        location: getTrackingLocation(this.$router),
        object_category: NAVIGATION_BUTTON,
        action: CLICK,
        object_instance: 'nav_more_open',
      });
    },
  },
};
</script>

<style lang="scss" module="$s">
.NavContainer {
  width: 100%;
  margin: 0 auto;
  position: relative;
  @include content-max-width;

  ul {
    align-items: center;
    justify-content: flex-start;
  }

  .AccountContainer {
    margin-left: var(--space-x6);
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;

    .SignUpButton {
      a,
      button {
        // This nesting is here to override orbit styles
        font-size: 16px;
      }
    }
  }
}

.LoginButton,
.DomainButton {
  display: none;
  margin-left: var(--space-x6);
  color: var(--text-black);
  text-decoration: none;
  font-size: 16px;
  font-weight: var(--font-weight-medium);

  &:hover {
    color: var(--text-black);
  }

  @media (--for-extra-large-up) {
    display: inline-block;
  }
}

.SignUpButton {
  display: none;
  margin-left: var(--space-x2);
  opacity: 0;
  transition: opacity var(--transition-base);

  @media (--for-medium-up) {
    display: inline-block;
  }

  &.Active {
    opacity: 1;
  }

  a {
    min-width: 123px;
    height: 40px;
    line-height: 37px;
    border: 1px solid var(--brand-color);
    border-radius: 4px;
    padding: 0 var(--space-x4);
    background-color: transparent;
    color: var(--brand-color);
    transition: background-color var(--transition-base);

    &:hover {
      background-color: #E6EEFF;
      color: var(--brand-color);
    }

    &:active {
      background-color: #CCDCFF
    }
  }
}

.NavLink {
  display: none;

  // This specificity madness is done to override
  // orbit defaults that are causing unwanted margins
  &.NavLinkSpecific.NavLinkMoreSpecific {
    margin-left: var(--space-x2);
    color: var(--text-black);

    &.firstNavLink {
      margin-left: 0;
    }

    span:hover {
      color: var(--text-black) !important;
    }

    a:hover {
      color: var(--text-black);
    }

    button {
      transition: none;
      margin-left: 0;
      color: var(--text-black);
      padding: 0;

      &:hover {
        color: inherit;
      }
    }
  }

  @media (--for-extra-large-up) {
    display: block;
  }
}

.NestedLinkTitle {
  margin-left: 0;
}

.NavLinkIndicatorArrow {
  position: relative;
  bottom: 1px;
}

// These styles override orbit defaults
.NestedNavLink {
  padding: 0 var(--space-x2);
  margin: 0 -8px;
  border-radius: var(--border-radius);

  &:first-child {
    margin-top: -8px;;
  }

  &:last-child {
    margin-bottom: -8px;
  }

  &:hover {
    background-color: #F5F6F7;
  }

  a {
    color: var(--color-gray-50) !important;
    font-size: 14px;
    font-weight: var(--font-weight-medium) !important;
    letter-spacing: 0;
    line-height: 16px;
    transition: none;

    &:hover {
      color: var(--text-black) !important;
    }
  }
}

.MobileNavIcon {
  display: block;
  align-self: center;
  color: var(--text-black);
  cursor: pointer;
  width: 24px;
  height: 14px;
  position: relative;

  @media (--for-extra-large-up) {
    display: none;
  }

  .IconBar,
  &::before,
  &::after {
    width: 100%;
    height: 2px;
    display: block;
    background: var(--text-black);
    border-radius: 1px;
    position: absolute;
  }

  .IconBar {
    opacity: 1;
    transition: opacity var(--transition-base);
    top: 6px;
  }

  &::before,
  &::after {
    content: '';
    transition: transform var(--transition-base);
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  &.Active {
    &::before {
      transform: rotate(45deg) translate3d(2px, 6px, 0);
    }

    &::after {
      transform: rotate(-45deg) translate3d(3px, -6px, 0);
    }

    .IconBar {
      opacity: 0;
    }
  }
}

.WeeblyLogo {
  text-align: center;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  align-self: center;
  margin: 0 auto;
  width: 103px;

  @media (--for-extra-large-up) {
    width: 127px;
  }

  .MobileLogo {
    display: block;

    @media (--for-extra-large-up) {
      display: none;
    }
  }

  .DesktopLogo {
    display: none;

    @media (--for-extra-large-up) {
      display: block;
    }
  }
}
</style>
