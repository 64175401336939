/* eslint no-param-reassign: 0 */
import axios from 'axios';
import { API_PREFIX } from '../shared/constants';

export const GET_PAGE_CONTENT = 'GET_PAGE_CONTENT';

export const state = () => ({
  language: 'en',
  pageContent: {},
  homepageRootComponent: null,
  env: {},
});

export const getters = {
  cdnBaseUrl(currentState) {
    let baseUrl = '';
    if (currentState.env.CDN_HOSTNAME) {
      baseUrl = currentState.env.CDN_HOSTNAME;
    }

    return baseUrl;
  },
};

export const mutations = {
  updatePageContent(currentState, payload) {
    currentState.pageContent = Object.assign({}, currentState.pageContent, payload);
  },

  updateLanguage(currentState, payload) {
    currentState.language = payload.language;
  },

  updateHomepageRootComponent(currentState, payload) {
    currentState.homepageRootComponent = payload;
  },

  updateEnv(currentState, payload) {
    currentState.env = payload;
  },
};

export const actions = {
  nuxtServerInit({ commit }) {
    if (process && process.env) {
      const envForStore = {
        WEEBLY_HOST: process.env.WEEBLY_HOST,
        IMAGE_ANALYZER_API: process.env.IMAGE_ANALYZER_API,
        CDN_HOSTNAME: process.env.CDN_HOSTNAME,
      };

      commit('updateEnv', envForStore);
    }
  },
  /**
   * This method makes a call to the page-content api to
   * retrieve data for the given page, and then commits
   * it to the store. It is commonly called from the fetch
   * method in a page template.
   *
   * @param {} storeContext
   * @param {} payload
   */
  async [GET_PAGE_CONTENT](storeContext, payload) {
    const nuxtContext = payload.context;
    const apiBase = nuxtContext.req ? `${nuxtContext.req.protocol}://${nuxtContext.req.get('host')}` : '';

    const pageContent = await axios.post(`${apiBase}/${API_PREFIX}/page-content`, {
      path: payload.routePath || nuxtContext.route.path,
      isFeaturePage: !!payload.isFeaturePage,
      language: storeContext.state.language,
    });

    if (pageContent.data) {
      storeContext.commit('updatePageContent', pageContent.data);
    }

    return pageContent.data;
  },
};
