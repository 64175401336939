// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageContainer_3DkLu{font-family:\"Square Market\";text-align:center}.Divider_fcSFG{border:1px solid #e7e7e7;border-top:0}.WarningContainer_SmTvg{background-color:#fff;border:1px solid #d4d4d4;box-shadow:0 0 41px -8px #edeaed;display:inline-block;font-size:14px;margin:10% auto 0;padding:40px 40px 20px;text-align:center}.Header_-5LoO{color:#363b3e;font-size:45px;font-weight:500;margin:26px}.Error_GY4J6{color:#9ba0a3;margin:0 0 35px}.CheckUrl_\\+d4X9,.Otherwise_qONX0{color:#666c70}.CheckUrl_\\+d4X9{font-weight:500;margin:0 0 20px}.Otherwise_qONX0{margin-top:0}.Logo_v2eGq{width:82px}.BottomContent_mcQqv{display:inline-block;height:120px;line-height:120px}.BottomContent_mcQqv>span{display:inline-block;line-height:normal;vertical-align:middle}a{color:#2990ea;text-decoration:inherit}@media (max-width:550px){.warning-container_1zCo2{width:80%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageContainer": "PageContainer_3DkLu",
	"Divider": "Divider_fcSFG",
	"WarningContainer": "WarningContainer_SmTvg",
	"Header": "Header_-5LoO",
	"Error": "Error_GY4J6",
	"CheckUrl": "CheckUrl_+d4X9",
	"Otherwise": "Otherwise_qONX0",
	"Logo": "Logo_v2eGq",
	"BottomContent": "BottomContent_mcQqv",
	"warning-container": "warning-container_1zCo2"
};
module.exports = ___CSS_LOADER_EXPORT___;
