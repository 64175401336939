<template>
  <o-layout
    :has-layers="true"
    :class="$s.LayoutContainer"
  >
    <nuxt/>
    <o-layout-footer
      :class="$s.FooterContainer"
    >
      <footer-nav
        :show-cookie-preferences="showCookiePreferences"
        :is-eu-one-trust="isEuOneTrust" />
      <hr :class="$s.FooterDividerTop">
      <footer-social />
      <hr :class="$s.FooterDividerBottom">
      <div
        :class="$s.FooterLegal"
      >
        {{ $store.state.pageContent.global.footerLegal.legalRights }}
      </div>
    </o-layout-footer>
  </o-layout>
</template>

<script>
import debounce from 'lodash.debounce';
import { OLayout, OLayoutHeader, OLayoutContent, OLayoutFooter, OLayoutSider } from 'orbit-ui/components/Layout';
import { OButton } from 'orbit-ui/components/Button';

import FooterNav from '../components/shared/FooterNav';
import FooterSocial from '../components/shared/FooterSocial';

let debouncedScrollHandler = null;

export default {
  layout: 'default',

  components: {
    OLayout,
    OLayoutHeader,
    OLayoutContent,
    OLayoutFooter,
    OLayoutSider,
    OButton,
    FooterNav,
    FooterSocial,
  },

  data() {
    return {
      isSidebarVisible: false,
      hasScrolled: false,
    };
  },

  computed: {
    showCookiePreferences() {
      return process.client && window.w_gdpr && (window.w_gdpr.is_eu_onetrust || window.w_gdpr.is_us_ca_onetrust);
    },
    isEuOneTrust() {
      return process.client && window.w_gdpr && window.w_gdpr.is_eu_onetrust;
    },
  },

  mounted() {
    debouncedScrollHandler = debounce(this.scrollHandler.bind(this), 100, { leading: true });
    window.addEventListener('scroll', debouncedScrollHandler);

    // window.history.scrollRestoration in router is breaking anchors on first load, so manually scroll to it
    // https://github.com/nuxt/nuxt.js/issues/4024
    if (this.$route.hash) {
      const el = document.getElementById(this.$route.hash.slice(1));
      window.scrollTo(0, el.offsetTop - 20);
    }
  },

  beforeDestroy() {
    window.removeEventListener('scroll', debouncedScrollHandler);
  },

  methods: {
    scrollHandler() {
      if (window.pageYOffset > 0) {
        this.hasScrolled = true;
      } else {
        this.hasScrolled = false;
      }
    },
  },
};
</script>

<style lang="scss" module="$s">
.LayoutContainer {
  max-width: $page-max-width;
  margin: 0 auto;
  background-color: white;
  overflow-x: hidden;
}

.FooterContainer {
  align-self: center;
  padding: var(--space-x4) 0;
  @include content-width;
}

.FooterDividerTop,
.FooterDividerBottom {
  width: 100%;
  height: 1px;
  background: var(--color-gray-30);
  border: 0;
}

.FooterDividerTop {
  margin-top: 0;
  margin-bottom: var(--space-x3);
}

.FooterDividerBottom {
  margin-top: var(--space-x3);
  margin-bottom: var(--space-x2);
}
</style>
