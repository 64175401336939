import supportedCountries from './supported-countries';

/**
 * This method tries to find a country scope code in
 * the path. It returns the country/language code
 * as well as a new path without the country code present.
 *
 * @param {string} route the route being requested
 * @param {Function} redirect the method used by nuxt to redirect the route
 */
const checkAndRedirectCountryScope = (route, redirect) => {
  if (!route.path) {
    return;
  }

  const pathParts = route.path.split('/').filter(pathPart => !!pathPart);
  const langForCountry = supportedCountries[pathParts[0]];
  const pathIncludesCountryScope = pathParts.length && langForCountry;

  if (pathIncludesCountryScope) {
    // Remove the country code in the path
    pathParts.shift();
    const newPath = `/${pathParts.join('/')}`;

    redirect(301, newPath, {
      ...route.query,
      lang: langForCountry,
    });
  }
};

export default checkAndRedirectCountryScope;
