<template>
  <o-layout
    :has-layers="true"
    :class="$s.LayoutContainer"
  >
    <div
      :class="[$s.HeaderContainer, { [$s.hasScrolled]: hasScrolled }]"
      :style="{
        color: $store.state.navbarTextColor,
        backgroundColor: $store.state.navbar.navbarBackgroundColor,
      }"
    >
      <o-layout-header>
        <header-navbar />
      </o-layout-header>
    </div>
    <nuxt/>
    <o-layout-footer
      :class="$s.FooterContainer"
    >
      <footer-nav />
      <hr :class="$s.FooterDividerTop">
      <footer-social />
      <hr :class="$s.FooterDividerBottom">
      <div
        :class="$s.FooterLegal"
      >
        {{ $store.state.pageContent.global.footerLegal.legalRights }}
      </div>
    </o-layout-footer>
  </o-layout>
</template>

<script>
import debounce from 'lodash.debounce';
import { OLayout, OLayoutHeader, OLayoutContent, OLayoutFooter, OLayoutSider } from 'orbit-ui/components/Layout';
import { OButton } from 'orbit-ui/components/Button';

import HeaderNavbar from '../components/shared/HeaderNavbar';
import FooterNav from '../components/shared/FooterNav';
import FooterSocial from '../components/shared/FooterSocial';

let debouncedScrollHandler = null;

export default {
  layout: 'default',

  components: {
    OLayout,
    OLayoutHeader,
    OLayoutContent,
    OLayoutFooter,
    OLayoutSider,
    OButton,
    HeaderNavbar,
    FooterNav,
    FooterSocial,
  },

  data() {
    return {
      isSidebarVisible: false,
      hasScrolled: false,
    };
  },

  mounted() {
    debouncedScrollHandler = debounce(this.scrollHandler.bind(this), 100, { leading: true });
    window.addEventListener('scroll', debouncedScrollHandler);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', debouncedScrollHandler);
  },

  methods: {
    scrollHandler() {
      if (window.pageYOffset > 0) {
        this.hasScrolled = true;
      } else {
        this.hasScrolled = false;
      }
    },
  },
};
</script>

<style lang="scss" module="$s">
.LayoutContainer {
  padding-top: var(--navbar-height);
  max-width: $page-max-width;
  margin: 0 auto;
  background-color: white;
  overflow-x: hidden;
}

.HeaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.3s ease-out, background-color 0.3s ease-out, color 0.3s ease-out;
  max-width: $page-max-width;
  margin: 0 auto;
  z-index: var(--z-index-header-mobile);

  @media(--for-extra-large-up) {
    // This lower z-index is necessary here to make sure the
    // 'more' overlay is not covered by the high z-index of
    // the navbar. The higher z-index used for the mobile
    // header container is necessary to make sure the navbar
    // is above the mobile menu takeover sheet to allow the
    // user to close the menu by clicking on the navbar buttons
    z-index: var(--z-index-header);
  }

  &.hasScrolled {
    border-bottom: 1px solid var(--color-gray-20);
    // The !importants are necessary here to override
    // the colors coming from the vuex store. We only
    // want to honor those color choices when the
    // user has not scrolled
    background-color: white !important;
    color: $text-black !important;
  }

  header {
    min-height: var(--navbar-height);
    padding: 0;
    background-color: inherit;
  }
}

.FooterContainer {
  align-self: center;
  padding: var(--space-x4) 0;
  @include content-width;
}

.FooterDividerTop,
.FooterDividerBottom {
  width: 100%;
  height: 1px;
  background: var(--color-gray-30);
  border: 0;
}

.FooterDividerTop {
  margin-top: 0;
  margin-bottom: var(--space-x3);
}

.FooterDividerBottom {
  margin-top: var(--space-x3);
  margin-bottom: var(--space-x2);
}
</style>
