import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c3902696 = () => interopDefault(import('../pages/tools/index.vue' /* webpackChunkName: "pages/tools/index" */))
const _86ca42ba = () => interopDefault(import('../pages/newsletter/confirm.vue' /* webpackChunkName: "pages/newsletter/confirm" */))
const _20bee072 = () => interopDefault(import('../pages/tools/privacy-policy/index.vue' /* webpackChunkName: "pages/tools/privacy-policy/index" */))
const _64667a38 = () => interopDefault(import('../pages/tools/template-terms/index.vue' /* webpackChunkName: "pages/tools/template-terms/index" */))
const _16ab20aa = () => interopDefault(import('../pages/tools/terms-and-conditions-generator/index.vue' /* webpackChunkName: "pages/tools/terms-and-conditions-generator/index" */))
const _315301ae = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _77956c01 = () => interopDefault(import('../pages/tools/privacy-policy/_data/index.vue' /* webpackChunkName: "pages/tools/privacy-policy/_data/index" */))
const _189a86a4 = () => interopDefault(import('../pages/tools/terms-and-conditions-generator/_data/index.vue' /* webpackChunkName: "pages/tools/terms-and-conditions-generator/_data/index" */))
const _67682db9 = () => interopDefault(import('../pages/online-store/_feature/index.vue' /* webpackChunkName: "pages/online-store/_feature/index" */))
const _273f5085 = () => interopDefault(import('../pages/websites/_feature.vue' /* webpackChunkName: "pages/websites/_feature" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/tools",
    component: _c3902696,
    name: "tools"
  }, {
    path: "/newsletter/confirm",
    component: _86ca42ba,
    name: "newsletter-confirm"
  }, {
    path: "/tools/privacy-policy",
    component: _20bee072,
    name: "tools-privacy-policy"
  }, {
    path: "/tools/template-terms",
    component: _64667a38,
    name: "tools-template-terms"
  }, {
    path: "/tools/terms-and-conditions-generator",
    component: _16ab20aa,
    name: "tools-terms-and-conditions-generator"
  }, {
    path: "/",
    component: _315301ae,
    name: "index"
  }, {
    path: "/tools/privacy-policy/:data",
    component: _77956c01,
    name: "tools-privacy-policy-data"
  }, {
    path: "/tools/terms-and-conditions-generator/:data",
    component: _189a86a4,
    name: "tools-terms-and-conditions-generator-data"
  }, {
    path: "/online-store/:feature",
    component: _67682db9,
    name: "online-store-feature"
  }, {
    path: "/websites/:feature?",
    component: _273f5085,
    name: "websites-feature"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
