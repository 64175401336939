/**
 * This is a map of supported countries. The key
 * is the country code, and the value is the language.
 */
const supportedCountries = {
  uk: 'en',
  ie: 'en',
  fr: 'fr',
  de: 'de',
  es: 'es',
  mx: 'es',
  co: 'es',
  ar: 'es',
  cl: 'es',
  pe: 'es',
  ve: 'es',
  ec: 'es',
  gt: 'es',
  cu: 'es',
  bo: 'es',
  do: 'es',
  hn: 'es',
  py: 'es',
  sv: 'es',
  ni: 'es',
  cr: 'es',
  pr: 'es',
  pa: 'es',
  uy: 'es',
  ca: 'en',
  au: 'en',
  jp: 'ja',
  at: 'de',
  nl: 'nl',
  br: 'pt',
  in: 'en',
  it: 'it',
  ch: 'de',
  hk: 'en',
  sg: 'en',
  za: 'en',
  be: 'nl',
  se: 'sv',
};

export default supportedCountries;
