const middleware = {}

middleware['navbar'] = require('../middleware/navbar.js')
middleware['navbar'] = middleware['navbar'].default || middleware['navbar']

middleware['set-language'] = require('../middleware/set-language.js')
middleware['set-language'] = middleware['set-language'].default || middleware['set-language']

middleware['utils/country-scope'] = require('../middleware/utils/country-scope.js')
middleware['utils/country-scope'] = middleware['utils/country-scope'].default || middleware['utils/country-scope']

middleware['utils/file-regex'] = require('../middleware/utils/file-regex.js')
middleware['utils/file-regex'] = middleware['utils/file-regex'].default || middleware['utils/file-regex']

middleware['utils/supported-countries'] = require('../middleware/utils/supported-countries.js')
middleware['utils/supported-countries'] = middleware['utils/supported-countries'].default || middleware['utils/supported-countries']

middleware['utils/supported-languages'] = require('../middleware/utils/supported-languages.js')
middleware['utils/supported-languages'] = middleware['utils/supported-languages'].default || middleware['utils/supported-languages']

export default middleware
