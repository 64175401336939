import {
  WEEBLY_ONLINE_STORES,
  WEEBLY_TOOLS_PHOTO_ANALYZER,
  WEEBLY_TOOLS_PRIVACY_POLICY,
  WEEBLY_TOOLS_TERMS_AND_CONDITIONS,
  WEEBLY_WEBSITES,
  WEEBLY_HOME,
} from '@weebly/aragorn-analytics/dist/constants/locations';

const getTrackingLocation = (router) => {
  const { currentRoute } = router;

  if (currentRoute.name.includes('tools-privacy-policy')) {
    return WEEBLY_TOOLS_PRIVACY_POLICY;
  } else if (currentRoute.name.includes('tools-terms-and-conditions-generator')) {
    return WEEBLY_TOOLS_TERMS_AND_CONDITIONS;
  } else if (currentRoute.name.includes('tools')) {
    return 'weebly.tools';
  } else if (currentRoute.name.includes('online-store')) {
    return WEEBLY_ONLINE_STORES;
  } else if (currentRoute.name.includes('websites')) {
    return WEEBLY_WEBSITES;
  } else if (currentRoute.name.includes('tools-photo-analyzer')) {
    return WEEBLY_TOOLS_PHOTO_ANALYZER;
  }

  return WEEBLY_HOME;
};

export default getTrackingLocation;
