/* eslint-disable */

/**
 * This logic is shamelessly stolen from
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
 * 
 * This is a polyfill for Object.entries so that it will
 * still work when King's Landing pages are loaded on an
 * older browser.
 */
if (!Object.entries) {
  Object.entries = function( obj ){
    var ownProps = Object.keys( obj ),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]];
    return resArray;
  };
}
