<template>
  <div :class="$s.PageContainer">
    <div :class="$s.WarningContainer">
      <a href="/">
        <img
          :class="$s.Logo"
          alt="404 error"
          src="//cdn1.editmysite.com/images/weebly-logo-blue.png"
        >
      </a>
      <h2 :class="$s.Header">
        {{ error.statusCode }}
      </h2>
      <p :class="$s.Error">
        {{ error.message }}
      </p>
      <hr :class="$s.Divider">
      <div :class="$s.BottomContent">
        <span>
          <p :class="$s.CheckUrl">
            Please check the URL.
          </p>
          <p :class="$s.Otherwise">
            Otherwise, <kings-link href="/">click here</kings-link> to be redirected to the homepage.
          </p>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  layout: 'empty',

  props: {
    error: {
      type: Object,
      default: () => ({ statusCode: 500, message: 'There was an error' }),
    },
  },

  head() {
    return {
      title: `${this.error.statusCode} - Error`,
      meta: [
        { name: 'robots', content: 'noarchive' },
      ],
    };
  },
};
</script>

<style module="$s">
  .PageContainer {
    text-align: center;
    font-family: 'Square Market';
  }

  .Divider {
    border: 1px solid #E7E7E7;
    border-top: 0;
  }

  .WarningContainer {
    text-align: center;
    display: inline-block;
    margin: 0 auto;
    font-size: 14px;
    padding: 40px 40px 20px;
    text-align: center;
    background-color: white;
    border: 1px solid #D4D4D4;
    margin-top: 10%;
    box-shadow: 0px 0px 41px -8px rgba(237, 234, 237, 1);
  }

  .Header {
    margin: 26px;
    color: #363B3E;
    font-size: 45px;
    font-weight: 500;
  }

  .Error {
    margin: 0;
    color: #9BA0A3;
    margin-bottom: 35px;
  }

  .CheckUrl, .Otherwise {
    color: #666C70;
  }

  .CheckUrl {
    margin: 0 0 20px;
    font-weight: 500;
  }

  .Otherwise {
    margin-top: 0;
  }

  .Logo {
    width: 82px;
  }

  .BottomContent {
    display: inline-block;
    height: 120px;
    line-height: 120px;
  }

  .BottomContent > span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }

  a {
    color: #2990EA;
    text-decoration: inherit;
  }

  @media (max-width: 550px) {
    .warning-container {
      width: 80%;
    }
  }
</style>
