const supportedLanguages = [
  {
    param: 'en',
    name: 'English',
  },
  {
    param: 'da',
    name: 'Dansk',
  },
  {
    param: 'de',
    name: 'Deutsch',
  },
  {
    param: 'es',
    name: 'Español',
  },
  {
    param: 'fr',
    name: 'Français',
  },
  {
    param: 'it',
    name: 'Italiano',
  },
  {
    param: 'ja',
    name: '日本語',
  },
  {
    param: 'ko',
    name: '한국어',
  },
  {
    param: 'nl',
    name: 'Nederlands',
  },
  {
    param: 'no',
    name: 'Norsk',
  },
  {
    param: 'pl',
    name: 'Polski',
  },
  {
    param: 'pt',
    name: 'Português',
  },
  {
    param: 'ru',
    name: 'Pусский',
  },
  {
    param: 'sv',
    name: 'Svenska',
  },
  {
    param: 'tr',
    name: 'Türkçe',
  },
  {
    param: 'zh_TW',
    name: '中文 (繁體)',
  },
  {
    param: 'zh_CN',
    name: '中文 (简体)',
  },
];

export default supportedLanguages;
