import Cookies from 'js-cookie';

import checkAndRedirectCountryScope from './utils/country-scope';

const LANGUAGE_COOKIE_NAME = 'language';
const LANGUAGE_QUERY_PARAM_NAME = 'lang';
const LANGUAGE_COOKIE_EXPIRATION_DAYS = 14;
const LANGUAGE_COOKIE_EXPIRATION_MILI = LANGUAGE_COOKIE_EXPIRATION_DAYS * 86400000;

/**
 * We check and set the language on every page
 * change, so that other parts of the app
 * will use the correct language when rendering
 */
export default ({
  store,
  route,
  redirect,
  res,
  req,
}) => {
  /**
   * First check to see if the country code is in the path.
   * If present, redirect to the plain url that does not have
   * the country code present and append a lang query param.
   * The reason for this redirect is to keep the paths simple
   * and match the nuxt folder structure, without having to
   * explicitly specify language specifc files in the directory
   * structure
   */
  checkAndRedirectCountryScope(route, redirect);

  /**
   * Next, check for the presence of a language cookie
   */
  let cookieLanguage = null;

  if (process.server) {
    const lang = req.cookies[LANGUAGE_COOKIE_NAME];

    // If the cookie does not exist, the call to req.cookies
    // will return the string "undefined", so we have to check
    // for that here
    if (lang && (lang !== 'undefined')) {
      cookieLanguage = lang;
    }
  } else {
    cookieLanguage = Cookies.get(LANGUAGE_COOKIE_NAME);
  }

  /**
   * Next, check for the presence of the lang query param
   */
  const queryLanguage = route.query[LANGUAGE_QUERY_PARAM_NAME] || null;

  /**
   * Last, set the new language in the store and in the cookies
   */
  const currentLanguage = store.state.language;
  const newLanguage = queryLanguage || cookieLanguage || currentLanguage;
  const queryLangOverwritesCookieLang = queryLanguage && (queryLanguage !== cookieLanguage);
  const currentLangDifferentThanNewLang = newLanguage !== currentLanguage;
  const shouldUpdateLanguage = queryLangOverwritesCookieLang || currentLangDifferentThanNewLang;

  if (shouldUpdateLanguage) {
    store.commit('updateLanguage', {
      language: newLanguage,
    });

    if (process.server && !res.headersSent) {
      res.cookie(LANGUAGE_COOKIE_NAME, newLanguage, { maxAge: LANGUAGE_COOKIE_EXPIRATION_MILI });
    } else {
      Cookies.set(LANGUAGE_COOKIE_NAME, newLanguage, { expires: LANGUAGE_COOKIE_EXPIRATION_DAYS });
    }
  }
};
