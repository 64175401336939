<template>
  <o-layout
    :has-layers="true"
    :class="$s.LayoutContainer"
  >
    <div>
      <o-layout-header :class="$s.header">
        <weebly-logomark />
      </o-layout-header>
    </div>
    <nuxt/>
  </o-layout>
</template>

<script>
import { OLayout, OLayoutHeader } from 'orbit-ui/components/Layout';

import WeeblyLogomark from '~/assets/img/weebly-logomark.svg';

export default {
  layout: 'default',

  components: {
    OLayout,
    OLayoutHeader,
    WeeblyLogomark,
  },
};
</script>

<style lang="scss" module="$s">
.header {
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0);
  border-radius: 2px 2px 0 0;
  margin-bottom: 49px;
  height: 72px;

  &:before {
    background-image: linear-gradient(rgba(0,0,0,.2),transparent);
    opacity: 1;
  }
}

.LayoutContainer {
  max-width: $page-max-width;
  margin: 0 auto;
  background-color: white;
  overflow-x: hidden;
  height: 100vh;
}
</style>
