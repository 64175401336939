/* eslint no-param-reassign: 0 */
const INITIAL_BACKGROUND_COLOR = '#fff';
const INITIAL_TEXT_COLOR = '#2f3337';

export const state = () => ({
  navbarBackgroundColor: INITIAL_BACKGROUND_COLOR,
  navbarTextColor: INITIAL_TEXT_COLOR,
});

export const mutations = {
  setNavbarBackgroundColor(currentState, payload) {
    currentState.navbarBackgroundColor = payload.navbarBackgroundColor;
  },

  setNavbarTextColor(currentState, payload) {
    currentState.navbarTextColor = payload.navbarTextColor;
  },

  setNavbarDefaults(currentState) {
    currentState.navbarTextColor = INITIAL_TEXT_COLOR;
    currentState.navbarBackgroundColor = INITIAL_BACKGROUND_COLOR;
  },
};
