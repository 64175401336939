var render = function render(){var _vm=this,_c=_vm._self._c;return _c('o-nav',{class:_vm.$s.NavContainer,attrs:{"horizontal":""}},[_c('li',{class:[_vm.$s.MobileNavIcon, {
      [_vm.$s.Active]: !!_vm.mobileNavLayer,
    }],on:{"click":_vm.toggleMobileTakeover}},[_c('span',{class:_vm.$s.IconBar})]),_c('o-nav-link',{class:_vm.$s.WeeblyLogo,attrs:{"to":"//www.weebly.com","aria-label":"Weebly"}},[_c('WeeblySquareLogo',{class:_vm.$s.DesktopLogo}),_c('WeeblyLogotype',{class:_vm.$s.MobileLogo})],1),_vm._l((_vm.navbarData.links),function(link,idx){return _c('o-nav-link',{key:idx,class:[
      _vm.$s.NavLink,
      _vm.$s.NavLinkSpecific,
      _vm.$s.NavLinkMoreSpecific,
      { [_vm.$s.firstNavLink]: idx === 0 }
    ],attrs:{"to":link.to},on:{"click":function($event){return _vm.trackNavClick(link)}}},[(!link.nested)?_c('span',[_vm._v(_vm._s(link.name))]):_vm._e(),(link.nested)?_c('o-popover',{attrs:{"placement":"bottom","offset":"0, 24"},scopedSlots:_vm._u([{key:"action",fn:function(popover){return [_c('span',{class:_vm.$s.NestedLinkTitle,on:{"click":function($event){popover.toggle(); _vm.trackMorePopover()}}},[_c('span',[_vm._v(_vm._s(link.name))]),_c('o-icon',{class:_vm.$s.NavLinkIndicatorArrow,attrs:{"name":"indicator-arrow-down"}})],1)]}}],null,true)},[_c('o-nav',{attrs:{"variant":"secondary"}},_vm._l((link.links),function(nestedLink,index){return _c('o-nav-link',{key:index,class:_vm.$s.NestedNavLink,attrs:{"to":nestedLink.to},on:{"click":function($event){return _vm.trackNavClick(link)}}},[_c('span',[_vm._v(_vm._s(nestedLink.name))])])}),1)],1):_vm._e()],1)}),_c('li',{class:_vm.$s.AccountContainer},[_c('a',{class:_vm.$s.DomainButton,attrs:{"href":_vm.navbarData.account.domain.to,"variant":"text"},on:{"click":function($event){$event.preventDefault();return _vm.searchDomains.apply(null, arguments)}}},[_c('o-icon',{attrs:{"name":"search"}}),_vm._v("\n      "+_vm._s(_vm.navbarData.account.domain.text)+"\n    ")],1),_c('a',{class:_vm.$s.LoginButton,attrs:{"href":_vm.navbarData.account.login.to}},[_vm._v("\n      "+_vm._s(_vm.navbarData.account.login.text)+"\n    ")]),_c('o-button',{class:[_vm.$s.SignUpButton, {
        [_vm.$s.Active]: !_vm.mobileNavLayer,
      }],attrs:{"href":_vm.navbarData.account.signup.to,"variant":"primary","size":"large"},on:{"click":function($event){$event.preventDefault();return _vm.userSignUp.apply(null, arguments)}}},[_vm._v("\n      "+_vm._s(_vm.navbarData.account.signup.text)+"\n    ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }