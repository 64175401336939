import Vue from 'vue';
import AragornAnalytics from '@weebly/aragorn-analytics';

export default function ({ app, store }) {
  Vue.use(AragornAnalytics, {
    router: app.router,
    host: store.state.env.WEEBLY_HOST,
    enableSnowplow: true,
    debug: process.env.NODE_ENV !== 'production',
  });
}
