export default async ({
  store,
  route,
}) => {
  if (route.name === 'online-store-features') {
    store.commit('navbar/setNavbarBackgroundColor', { navbarBackgroundColor: '#F4F4F4' });
  } else {
    store.commit('navbar/setNavbarDefaults');
  }
};
