<template>
  <div>
    <div
      :class="$s.FooterNavContainer"
    >
      <div
        v-for="(section, idx) in footerNavData"
        :key="idx"
        :class="$s.NavColumn"
      >
        <div
          :class="$s.NavTitle"
        >
          {{ section.title }}
        </div>
        <kings-link
          v-for="(link, idx) in section.links"
          :class="$s.NavLink"
          :href="link.to"
          :key="idx"
          :click-action="link.onClick"
        >
          {{ link.title }}
        </kings-link>
      </div>
    </div>
    <o-nav
      :class="$s.MobileFooter"
      variant="secondary"
    >
      <o-nav-link
        v-for="(section, idx) in footerNavData"
        :key="idx"
        :label="section.title"
        :class="[$s.NavLink, $s.NavLinkSpecific]"
      >
        {{ section.title }}
        <template
          slot="children"
        >
          <o-nav-link
            v-for="(nestedLink, index) in section.links"
            :key="index"
            :to="nestedLink.to"
            :class="$s.NestedNavLink"
          >
            <span
              :class="$s.ActionNavLink"
              v-on="nestedLink.onClick ? { click: nestedLink.onClick } : {}">
              {{ nestedLink.title }}
            </span>
          </o-nav-link>
        </template>
      </o-nav-link>
    </o-nav>
  </div>
</template>

<script>
import { ONav, ONavLink } from 'orbit-ui/components/Nav';
import { OButton } from 'orbit-ui/components/Button';
import { OIcon } from 'orbit-ui/components/Icon';
import { OGrid } from 'orbit-ui/components/Grid';
import { OAccordion, OAccordionItem } from 'orbit-ui/components/Accordion';

export default {
  name: 'FooterNav',

  components: {
    OButton,
    ONav,
    ONavLink,
    OIcon,
    OGrid,
    OAccordion,
    OAccordionItem,
  },

  props: {
    showCookiePreferences: {
      type: Boolean,
      default: false,
    },
    isEuOneTrust: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // We set the v-model of accordion to -1
      // to have it default to a closed state
      accordionDefaultValue: -1,
      footerNavData: this.$store.state.pageContent.global.footerNav,
    };
  },

  mounted() {
    this.$data.footerNavData = this.addOneTrustLinks(this.$data.footerNavData);
  },

  methods: {
    // Add on OneTrust cookie links for EU/US/CA
    addOneTrustLinks(footerNavData) {
      if (this.showCookiePreferences) {
        const companySection = footerNavData.find(section => section.title === 'Company');
        if (companySection) {
          const cookieLink = {
            title: this.isEuOneTrust ? 'Cookie preferences' : 'Opt-Out of Interest-Based Advertising',
            onClick: this.openCookiePreferences,
          };
          companySection.links.push(cookieLink);
        }
      }
      return [...footerNavData];
    },
    openCookiePreferences() {
      return window.Optanon && window.Optanon.ToggleInfoDisplay();
    },
  },
};
</script>

<style lang="scss" module="$s">
.FooterNavContainer {
  display: none;
  margin: 0 auto;

  @media(--for-small-up) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @media(--for-extra-large-up) {
    flex-wrap: none;
  }

  .NavColumn {
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--space-x5);

    @media(--for-small-up) {
      flex-basis: 50%;
    }

    @media(--for-extra-large-up) {
      flex-basis: 20%;
    }

    .NavTitle {
      color: var(--color-gray-50);
      margin-bottom: var(--space-x3);
    }

    .NavLink {
      color: var(--color-gray-60);
      display: block;
      text-decoration: none;
      padding: 0;
      margin-bottom: var(--space-x2);
      line-height: var(--space-x2);
    }
  }
}

.MobileFooter {
  display: block;
  margin-bottom: var(--space-x3);

  @media(--for-small-up) {
    display: none;
  }

  .NavLink.NavLinkSpecific {
    border-bottom: 1px solid #E5E5E5;

    &:last-child {
      border: none;
    }

    a, button {
      position: relative;
      color: $text-black;
      padding: var(--space-x3) 0;
      font-weight: var(--font-weight-medium);
    }

    button::after {
      position: absolute;
      right: var(--space-x2);
      top: 30px;
    }

    .NestedNavLink {
      a, .ActionNavLink {
        color: var(--color-gray-50);
        font-size: 14px;
        line-height: 16px;
        padding: var(--space) 0;
        font-weight: var(--font-weight-medium);
      }

      &:last-child {
        padding-bottom: var(--space-x3);
      }
    }
  }
}
</style>
